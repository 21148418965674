<template>
  <div class="parlay-desglose">
    <div class="parlay-desglose__contclose">
      <div class="parlay-desglose__contclose__item" @click="onShowClick">
        <div class="parlay-desglose__contclose__item__text">Cerrar</div>
        <div class="parlay-desglose__contclose__item__icon"><img src="/images/icn_close.png" /></div>
      </div>
    </div>
    <div class="parlay-desglose__infocont">
      <template v-if="isTips">
        <div
          class="parlay-desglose__infocont__contbuttontips"
          @click="
            clickShowFreeSection();
            messageAnalyticsDiversitification();
          "
        >
          <div class="button-making-money">
            <div class="button-making-money__text">
              Estrategia Diversificación de Riesgo
            </div>
            <div class="button-making-money__icon"><img src="/images/icn_eye.png" /></div>
          </div>
        </div>
      </template>
      <template v-if="isTips">
        <div class="parlay-desglose__infocont__contheader" @click="messageAnalyticsBreakdown">
          <div class="parlay-desglose__infocont__contheader__header" :class="isMobile ? 'margin-top: -14px' : ''">
            <template v-if="!isMobile2">Desglose de Tip Científico por jornada:</template>
            <template v-else>Desglose de Tip Científico <br />por jornada:</template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="parlay-desglose__infocont__contheader" @click="messageAnalyticsBreakdown">
          <div class="parlay-desglose__infocont__contheader__header">
            <template v-if="!isMobile2">Desglose de Tip Científico por jornada:</template>
            <template v-else>Desglose de Tip Científico <br />por jornada:</template>
          </div>
        </div>
      </template>

      <div>
        <template v-for="(item, key) in getTipsFreeBreakdown">
          <template v-if="key < 6">
            <div class="item-game" :key="key">
              <div class="flag-tablet">
                <div class="item-game__matchday" :data="item['matchday']">
                  <template v-if="isMobile">{{ item['matchday'] }}</template>
                  <template v-else>{{ item['match'] }}</template>
                  <div class="item-game__matchday__icon" @click="clickCollapse(key)">
                    <template v-if="matchs[key]"><img src="/images/icn-colapsar.png"/></template>
                    <template v-else><img src="/images/icn-expandir.png"/></template>
                  </div>
                </div>
                <div class="item-game__contgames" v-if="matchs[key]">
                  <!-- aqui -->
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][0]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][0]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][0]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][0]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][0]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][0]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][0]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][0]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][0]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][0]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          >
                        </span>
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money">
                          <template v-if="item['parlay'][0]['roi'] >= 0">${{ item['parlay'][0]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][0]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- aqui -->
                  <div class="item-game__contgames__more">+</div>
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][1]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][1]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][1]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][1]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][1]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][1]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][1]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][1]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][1]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][1]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          >
                        </span>
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][1]['roi'] >= 0">${{ item['parlay'][1]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][1]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="item-game__contgames__more">+</div>
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][2]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][2]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][2]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][2]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][2]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][2]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][2]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][2]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][2]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][2]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          ></span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][2]['roi'] >= 0">${{ item['parlay'][2]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][2]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="item-game__contgames__more">+</div>
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][3]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][3]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][3]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][3]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][3]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][3]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][3]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][3]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][3]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][3]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          ></span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][3]['roi'] >= 0">${{ item['parlay'][3]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][3]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-game__equal">=</div>
              <div class="item-game__contgain">
                <template v-if="item['gain'] < 0">
                  <div class="item-game__contgain__top">
                    <div>
                      <div>
                        <div class="text">Apostado:</div>
                        <div class="amount">{{ item.investment.toFixed(2) }}</div>
                      </div>
                      <div class="separator"></div>
                    </div>
                    <div>
                      <div class="text">Payout:</div>
                      <div class="amount">${{ item.payout.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="item-game__contgain__bottom">
                    <div class="item-game__contgain__equal">=</div>
                    <div class="item-game__contgain__images"><img src="/images/pink_arrow.png" /></div>
                    <div class="item-game__contgain__money pink-color">
                      {{ item['gain'] }}.<span class="item-game__contgain__money__min">{{ item['gaindeci'] }}</span>
                      <span class="item-game__contgain__money__type pink-color">&nbsp;MXN</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="item-game__contgain__top">
                    <div>
                      <div>
                        <div class="text">Apostado:</div>
                        <div class="amount">{{ item.investment.toFixed(2) }}</div>
                      </div>
                      <div class="separator"></div>
                    </div>
                    <div>
                      <div class="text">Payout:</div>
                      <div class="amount">${{ item.payout.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="item-game__contgain__bottom">
                    <div class="item-game__contgain__equal">=</div>
                    <div class="item-game__contgain__images"><img src="/images/green_arrow.png" /></div>
                    <div class="item-game__contgain__money">
                      +{{ item['gain'] }}.<span class="item-game__contgain__money__min">{{ item['gaindeci'] }}</span>
                      <span class="item-game__contgain__money__type">&nbsp;MXN</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div v-if="showgames">
        <template v-for="(item, key) in getTipsFreeBreakdown">
          <template v-if="key > 5">
            <div class="item-game" :key="key">
              <div class="flag-tablet">
                <div class="item-game__matchday" :data="item['matchday']">
                  <template v-if="isMobile">{{ item['matchday'] }}</template>
                  <template v-else>{{ item['match'] }}</template>
                  <div class="item-game__matchday__icon" @click="clickCollapse(key)">
                    <template v-if="matchs[key]"><img src="/images/icn-colapsar.png"/></template>
                    <template v-else><img src="/images/icn-expandir.png"/></template>
                  </div>
                </div>
                <div class="item-game__contgames" v-if="matchs[key]">
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][0]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][0]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][0]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][0]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][0]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][0]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][0]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][0]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][0]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][0]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          ></span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][0]['roi'] >= 0">${{ item['parlay'][0]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][0]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="item-game__contgames__more">+</div>
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][1]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][1]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][1]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][1]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][1]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][1]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][1]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][1]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money">{{
                          item['parlay'][1]['investment']
                        }}</span>
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][1]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          ></span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][1]['roi'] >= 0">${{ item['parlay'][1]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][1]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="item-game__contgames__more">+</div>
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][2]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][2]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][2]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][2]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][2]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][2]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][2]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][2]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][2]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][2]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          ></span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][2]['roi'] >= 0">${{ item['parlay'][2]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][2]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="item-game__contgames__more">+</div>
                  <div class="item-game__contgames__gameitem">
                    <div class="item-game__contgames__gameitem__team">
                      <template v-if="item['parlay'][3]['bet'] == 'Empata'">
                        <div class="item-game-value">
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][3]['logo_home']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][3]['name_home'] }}</div>
                          </div>
                          <div class="item-game-value__vs">VS</div>
                          <div class="item-game-value__team">
                            <div class="item-game-value__team__shield">
                              <img width="25" :src="item['parlay'][3]['logo_away']" />
                            </div>
                            <div class="item-game-value__team__name">{{ item['parlay'][3]['name_away'] }}</div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="item-game__contgames__gameitem__team__shield">
                          <img :src="item['parlay'][3]['logo']" />
                        </div>
                        <div class="item-game__contgames__gameitem__team__name">{{ item['parlay'][3]['name'] }}</div>
                      </template>
                    </div>
                    <div class="flag-continfo">
                      <div class="item-game__contgames__gameitem__bet">{{ item['parlay'][3]['bet'] }}</div>
                      <div class="item-game__contgames__gameitem__moneybet">
                        <span class="item-game__contgames__gameitem__moneybet__title">Apostado:</span
                        ><span class="item-game__contgames__gameitem__moneybet__money"
                          >${{ item['parlay'][3]['investment'] }}</span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__result">
                        <span class="item-game__contgames__gameitem__result__icon">
                          <template v-if="item['parlay'][3]['roi'] > 0"
                            ><img src="/images/correct.png" /><span class="item-game__contgames__gameitem__result__text"
                              >Acierto</span
                            ></template
                          >
                          <template v-else
                            ><img src="/images/incorrect.png" /><span
                              class="item-game__contgames__gameitem__result__text"
                              >Falla</span
                            ></template
                          ></span
                        >
                      </div>
                      <div class="item-game__contgames__gameitem__moneyagain">
                        <span class="item-game__contgames__gameitem__moneyagain__title">Payout:</span
                        ><span class="item-game__contgames__gameitem__moneyagain__money"
                          ><template v-if="item['parlay'][3]['roi'] >= 0">${{ item['parlay'][3]['roi'] }}</template>
                          <template v-else>-${{ Math.abs(item['parlay'][3]['roi']).toFixed(2) }}</template></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item-game__equal">=</div>
              <div class="item-game__contgain">
                <template v-if="item['gain'] < 0">
                  <div class="item-game__contgain__top">
                    <div>
                      <div>
                        <div class="text">Apostado:</div>
                        <div class="amount">{{ item.investment.toFixed(2) }}</div>
                      </div>
                      <div class="separator"></div>
                    </div>
                    <div>
                      <div class="text">Payout:</div>
                      <div class="amount">${{ item.payout.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="item-game__contgain__bottom">
                    <div class="item-game__contgain__equal">=</div>
                    <div class="item-game__contgain__images"><img src="/images/pink_arrow.png" /></div>
                    <div class="item-game__contgain__money pink-color">
                      {{ item['gain'] }}.<span class="item-game__contgain__money__min">{{ item['gaindeci'] }}</span>
                      <span class="item-game__contgain__money__type pink-color">&nbsp;MXN</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="item-game__contgain__top">
                    <div>
                      <div>
                        <div class="text">Apostado:</div>
                        <div class="amount">{{ item.investment.toFixed(2) }}</div>
                      </div>
                      <div class="separator"></div>
                    </div>
                    <div>
                      <div class="text">Payout:</div>
                      <div class="amount">${{ item.payout.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="item-game__contgain__bottom">
                    <div class="item-game__contgain__equal">=</div>
                    <div class="item-game__contgain__images"><img src="/images/green_arrow.png" /></div>
                    <div class="item-game__contgain__money">
                      +{{ item['gain'] }}.<span class="item-game__contgain__money__min">{{ item['gaindeci'] }}</span>
                      <span class="item-game__contgain__money__type">&nbsp;MXN</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="parlay-desglose__infocont__contshowmore">
        <div class="line-separator"></div>
        <div class="parlay-desglose__infocont__contshowmore__button">
          <div class="parlay-desglose__infocont__contshowmore__button__cont" @click="clickShowMoreGames">
            <div class="parlay-desglose__infocont__contshowmore__button__cont__title">
              <template v-if="!showgames">Ver más Jornadas</template>
              <template v-else>Ver menos Jornadas</template>
            </div>
            <div class="parlay-desglose__infocont__contshowmore__button__cont__image">
              <template v-if="!showgames"><img src="/images/icn_seplegar.png"/></template>
              <template v-else><img src="/images/icn_colapsar.png"/></template>
            </div>
          </div>
        </div>
      </div>
      <div class="parlay-desglose__infocont__conttotal">
        <div class="parlay-desglose__infocont__conttotal__total-item">
          <div class="parlay-desglose__infocont__conttotal__total-item__title">TOTAL</div>
          <div class="parlay-desglose__infocont__conttotal__total-item__money">${{ getTipsFreeGain }} MXN</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { tipsDiversification, tipsBreakdown, tipsBreakdownSeeMore, tipsBreakdownSeeLess } from '@/utils/analytics';

export default {
  name: 'ParlayDesglose',
  components: {},
  data() {
    return {
      showgames: false,
      matchs: [],
    };
  },
  props: {
    isTips: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapGetters('betsGeneral', ['getTipsFreeBreakdown', 'getTipsFreeGain']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    isMobile() {
      return this.displayWidth < 680;
    },
    isMobile2() {
      return this.displayWidth < 400;
    },
    flagAcordeon() {
      return this.matchs;
    },
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler() {
        if (this.displayWidth < 512) {
          for (let index = 0; index < this.getTipsFreeBreakdown.length; index++) {
            this.matchs[index] = index == 0;
          }
        } else {
          for (let index = 0; index < this.getTipsFreeBreakdown.length; index++) {
            this.matchs[index] = true;
          }
        }
      },
    },
    getTipsFreeBreakdown: {
      immediate: true,
      handler() {
        if (this.displayWidth < 512) {
          for (let index = 0; index < this.getTipsFreeBreakdown.length; index++) {
            this.matchs[index] = index == 0;
          }
        } else {
          for (let index = 0; index < this.getTipsFreeBreakdown.length; index++) {
            this.matchs[index] = true;
          }
        }
      },
    },
  },
  methods: {
    clickCollapse(key) {
      this.$set(this.matchs, key, !this.matchs[key]);
    },
    clickShowFreeSection() {
      this.$emit('changeView', 'free');
    },
    onShowClick() {
      this.$emit('show');
    },
    clickShowMoreGames() {
      this.showgames = !this.showgames;
      if (this.showgames) {
        tipsBreakdownSeeMore({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
      } else {
        tipsBreakdownSeeLess({
          is_premium: this.getPremiumAccount,
          golstats: this.getIsGolstats,
        });
      }
    },
    messageAnalyticsDiversitification() {
      tipsDiversification({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
    messageAnalyticsBreakdown() {
      tipsBreakdown({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item-game-value {
  width: 100%;
  height: 62px;
  display: inline-flex;
  justify-content: center;
  @media screen and (max-width: 512px) {
    margin-top: 25px;
  }
  &__team {
    width: 40%;
    height: 52px;
    display: inline-block;
    margin-top: 9px;
    justify-content: center;
    @media screen and (max-width: 512px) {
      width: 44%;
    }
    &__shield {
      width: 100%;
      height: 27px;
      @media screen and (max-width: 512px) {
        height: 35px;
      }
      img {
        @media screen and (max-width: 512px) {
          width: 30px !important;
        }
      }
    }
    &__name {
      width: 100%;
      height: 18px;
      line-height: 18px;
      font-family: 'Roboto-Bold';
      font-size: 12px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @media screen and (max-width: 512px) {
        font-size: 15px;
      }
    }
  }
  &__vs {
    margin-top: 9px;
    width: 12%;
    height: 52px;
    font-family: 'Roboto-Regular';
    font-size: 10px;
    line-height: 52px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}
.flag-continfo {
  @media screen and (max-width: 512px) {
    width: 50%;
    margin-left: 4px;
    margin-top: 2px;
  }
}
.flag-tablet {
  display: inline-flex;
  @media screen and (max-width: 980px) {
    display: inline-flex;
    width: 100%;
  }
  @media screen and (max-width: 680px) {
    display: inline-block;
  }
}
.button-making-money {
  width: 406px;
  height: 74px;
  border-radius: 7px;
  background-color: #132839;
  display: inline-flex;
  justify-content: center;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  @media screen and (max-width: 490px) {
    width: 270px;
    height: 64px;
  }
  &__icon {
    width: 29px;
    height: 18px;
    margin-top: 21px;
    margin-left: 4px;
    @media screen and (max-width: 490px) {
      margin-top: 19px;
    }
    img {
      width: 29px;
      @media screen and (max-width: 490px) {
        width: 24px;
      }
    }
  }
  &__text {
    font-family: 'Heebo';
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 74px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding-right: 5px;
    @media screen and (max-width: 490px) {
      font-size: 12px;
      line-height: 64px;
    }
  }
}
.line-separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
}
.pink-color {
  color: #ee6b83 !important;
}
.parlay-desglose {
  width: 87.16%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 60px;
  @media screen and (max-width: 880px) {
    width: 95.16%;
  }
  @media screen and (max-width: 730px) {
    width: 100%;
  }
  &__contclose {
    width: 100%;
    height: 50px;

    &__item {
      width: 118px;
      height: 50px;
      float: right;
      display: inline-flex;
      justify-content: space-between;
      @media screen and (max-width: 680px) {
        margin-right: 3%;
      }
      &__text {
        width: 88px;
        height: 50px;
        font-family: 'Roboto-Regular';
        font-size: 23px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 50px;
        letter-spacing: -0.36px;
        text-align: center;
        color: #313a42;
        @media screen and (max-width: 680px) {
          font-size: 18px;
          text-align: right;
          padding-right: 8px;
          line-height: 48px !important;
        }
      }
      &__icon {
        width: 30px;
        height: 50px;
        img {
          margin-top: 10px;
        }
      }
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
  &__infocont {
    width: 100%;
    height: auto;
    border-radius: 24px;
    border: solid 1px #d3d9de;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 96%;
    }
    &__contbuttontips {
      width: 100%;
      height: 74px;
      margin-top: 24px;
      @media screen and (max-width: 490px) {
        height: 64px;
      }
    }
    &__contheader {
      width: 100%;
      height: 60px;
      margin-top: 34px;
      margin-bottom: 26px;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 680px) {
        margin-top: 14px;
        margin-bottom: 0px;
        height: 30px;
      }
      @media screen and (max-width: 400px) {
        height: 60px;
      }
      &__header {
        width: 100%;
        height: 60px;
        font-family: 'Roboto-Black';
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 60px;
        letter-spacing: -0.56px;
        text-align: center;
        color: #313a42;
        @media screen and (max-width: 980px) {
          font-size: 26px;
        }
        @media screen and (max-width: 780px) {
          font-size: 24px;
        }
        @media screen and (max-width: 680px) {
          // font-size: 20px;
          width: 100%;
          // line-height: 30px;
        }
        @media screen and (max-width: 480px) {
          font-size: 21px;
        }
        @media screen and (max-width: 400px) {
          font-size: 22px;
          line-height: 24px;
          height: 60px;
        }
      }
    }
    &__contshowmore {
      width: 925px;
      height: 44px;
      margin-top: 56px;
      position: relative;
      display: inline-flex;
      justify-content: center;
      @media screen and (max-width: 980px) {
        width: 703px;
      }

      @media screen and (max-width: 780px) {
        width: 653px;
      }
      @media screen and (max-width: 680px) {
        width: 94%;
      }
      &__button {
        width: 241px;
        height: 44px;
        background: white;
        margin: 0 auto;
        position: absolute;
        &__cont {
          width: 221px;
          height: 44px;
          background: white;
          margin: 0 auto;
          border-radius: 8px;
          border: solid 1px #c6d3df;
          display: inline-flex;
          justify-content: center;
          &__title {
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 44px;
            letter-spacing: normal;
            text-align: center;
            color: #505a64;
            padding-right: 14px;
          }
          &__image {
            margin-top: 10px;
          }
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
    &__conttotal {
      width: 100%;
      height: 144px;
      margin-top: 56px;
      margin-bottom: 72px;
      display: inline-flex;
      justify-content: center;
      &__total-item {
        width: 925px;
        height: 144px;
        border-radius: 24px;
        border: solid 4px #cbee6b;
        @media screen and (max-width: 980px) {
          width: 703px;
        }
        @media screen and (max-width: 780px) {
          width: 653px;
        }
        @media screen and (max-width: 680px) {
          width: 94%;
        }
        &__title {
          width: 97%;
          height: 34px;
          border-radius: 7px;
          background-color: #132839;
          margin: 0 auto;
          margin-top: 20px;
          line-height: 34px;
          font-family: 'Roboto-Medium';
          font-size: 20px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
        &__money {
          width: 97%;
          height: 46px;
          border-radius: 12px;
          background-color: #cbee6b;
          margin: 0 auto;
          margin-top: 16px;
          line-height: 46px;
          font-family: 'Roboto-Black';
          font-size: 28px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -0.56px;
          text-align: center;
          color: #132839;
        }
      }
    }
  }
}
.item-game {
  width: 925px;
  height: 208px;
  margin: 0 auto;
  margin-top: 24px;
  border: solid 1px #b1c2d0;
  background: white;
  border-radius: 16px;
  display: inline-flex;
  @media screen and (max-width: 1120px) {
    width: 803px;
  }
  @media screen and (max-width: 980px) {
    width: 703px;
    height: fit-content !important;
    padding-bottom: 0.6rem;
    display: inline-block;
  }
  @media screen and (max-width: 780px) {
    width: 653px;
  }
  @media screen and (max-width: 680px) {
    width: 94%;
    height: 333px;
    display: inline-block;
  }
  @media screen and (max-width: 512px) {
    height: auto;
    padding-bottom: 10px;
  }

  &__matchday {
    width: 75px;
    height: 182px;
    margin-left: 16px;
    margin-top: 12px;
    border-radius: 12px;
    background-color: #132839;
    font-family: 'Roboto-Medium';
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 182px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    @media screen and (max-width: 980px) {
      margin-left: 8px;
      width: 65px;
    }
    @media screen and (max-width: 780px) {
      width: 60px;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin-left: 1.5%;
      height: 46px;
      line-height: 46px;
    }
    &__icon {
      display: none;
      float: right;
      width: 46px;
      height: 46px;
      @media screen and (max-width: 512px) {
        display: inline-block;
      }
      img {
        display: inline-block;
        margin-top: 10px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
  &__contgames {
    width: 550px;
    height: 182px;
    margin-left: 12px;
    margin-top: 12px;
    display: inline-flex;
    @media screen and (max-width: 1120px) {
      width: 455px;
    }
    @media screen and (max-width: 980px) {
      margin-left: 8px;
      width: 604px;
    }
    @media screen and (max-width: 780px) {
      width: 564px;
    }

    @media screen and (max-width: 680px) {
      width: 96%;
      margin: 0 auto;
      margin-top: 12px;
    }
    @media screen and (max-width: 512px) {
      display: inline-block;
      height: auto;
    }
    //aqui aqui
    &__gameitem {
      width: 118px;
      height: 182px;
      position: relative;
      @media screen and (max-width: 1120px) {
        width: 100px;
      }
      @media screen and (max-width: 980px) {
        width: 132px;
      }
      @media screen and (max-width: 780px) {
        width: 125px;
      }
      @media screen and (max-width: 680px) {
        width: 45%;
      }
      @media screen and (max-width: 512px) {
        display: inline-flex;
        width: 100%;
        height: 128px;
      }
      &__moneybet {
        width: 100%;
        height: 22px;
        margin-top: 4px;
        border-radius: 8px;
        background-color: #132839;

        display: inline-flex;
        justify-content: center;
        &__title {
          font-family: 'Roboto-Medium';
          font-size: 9px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-right: 4px;
          line-height: 21px;
        }
        &__money {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-left: 4px;
          line-height: 22px;
        }
      }
      &__moneyagain {
        width: 100%;
        height: 22px;
        margin-top: 4px;
        border-radius: 8px;
        background-color: #132839;
        display: inline-flex;
        justify-content: center;
        &__title {
          font-family: 'Roboto-Medium';
          font-size: 9px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-right: 4px;
          line-height: 21px;
        }
        &__money {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-left: 4px;
          line-height: 22px;
        }
      }
      &__result {
        width: 100%;
        height: 30px;
        margin-top: 3px;
        background-color: white;
        display: inline-flex;
        justify-content: center;
        line-height: 30px;
        &__icon {
          margin-top: 2px;
          padding-right: 2px;
        }
        &__text {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #132839;
          padding-left: 2px;
        }
      }
      &__team {
        width: 100%;
        height: 62px;
        border-radius: 12px;
        background-color: #0f92ff;
        display: inline-flex;
        justify-content: center;
        &__shield {
          width: 50px;
          height: 50px;
          margin-top: 6px;
          @media screen and (max-width: 512px) {
            margin-top: 35px;
          }
        }
        &__name {
          width: 50px;
          height: 50px;
          margin-top: 6px;
          line-height: 50px;
          font-family: 'Roboto-Bold';
          font-size: 18px;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
          padding-left: 4px;
          @media screen and (max-width: 512px) {
            margin-top: 34px;
          }
        }
        @media screen and (max-width: 512px) {
          width: 50%;
          height: 116px;
          margin-top: 6px;
          margin-bottom: 6px;
        }
      }
      &__bet {
        width: 100%;
        height: 30px;
        margin-top: 4px;
        border-radius: 8px;
        background-color: #cbee6b;
        font-family: 'Roboto-Bold';
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 30px;
        letter-spacing: normal;
        text-align: center;
        color: #000;
      }
    }
    &__more {
      width: 26px;
      height: 182px;
      font-family: 'Roboto-Regular';
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 182px;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      @media screen and (max-width: 1120px) {
        width: 18px;
      }
      @media screen and (max-width: 980px) {
        width: 26px;
      }

      @media screen and (max-width: 680px) {
        width: 10%;
      }
      @media screen and (max-width: 512px) {
        width: 100%;
        height: 18px;
        margin-top: 6px;
        margin-bottom: 6px;
        text-align: center;
        line-height: 18px;
      }
    }
  }
  &__betinfo {
    width: 160px;
    height: 96px;
    border-radius: 12px;
    background-color: #132839;
    margin-left: 12px;
    margin-top: 12px;
    @media screen and (max-width: 980px) {
      margin-left: 8px;
      width: 135px;
    }
    @media screen and (max-width: 780px) {
      width: 126px;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin: 0 auto;
      height: 51px;
      line-height: 51px;
      margin-top: 14px;
      display: inline-flex;
      justify-content: center;
    }
    &__title {
      width: 100%;
      height: 20px;
      margin-top: 25px;
      font-family: 'Roboto-Regular';
      font-size: 18px;
      font-stretch: normal;
      font-style: normal;
      line-height: 20px;
      letter-spacing: normal;
      text-align: center;
      opacity: 0.6;
      color: #fff;
      @media screen and (max-width: 680px) {
        margin-top: 0px;
        width: auto;
        line-height: 51px;
        padding-right: 8px;
      }
    }
    &__money {
      width: 100%;
      height: 26px;
      font-family: 'Roboto-Bold';
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      @media screen and (max-width: 680px) {
        width: auto;
        line-height: 51px;
      }
    }
  }
  &__equal {
    width: 26px;
    height: 182px;
    text-align: center;
    line-height: 182px;
    font-size: 24px;
    font-family: 'Roboto-Regular';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 12px;
    letter-spacing: normal;
    color: #000;
    @media screen and (max-width: 980px) {
      display: none;
    }
    @media screen and (max-width: 680px) {
      width: 97%;
      margin: 0 auto;
      height: 30px;
      line-height: 30px;
      margin-top: 0px;
    }
    @media screen and (max-width: 512px) {
      display: inline-block;
      margin-top: 6px;
    }
  }
  &__contgain {
    width: 224px;
    height: 182px;
    margin-top: 12px;
    border-radius: 12px;
    background-color: #132839;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.375rem 0;
    @media screen and (max-width: 1120px) {
      width: 200px;
    }
    @media screen and (max-width: 980px) {
      width: 96%;
      height: 9.5rem;
      margin: 10px 2%;
      justify-content: space-between;
      padding: 1.8rem 0;
    }

    &__top {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      font-size: 0.8125rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8d98a1;

      & > div:first-child {
        display: flex;
        align-items: center;

        & div:first-child {
          width: 100%;
        }
      }

      & .text {
        width: 100%;
        font-family: Roboto-Regular, sans-serif;
        font-weight: 900;
      }

      & .amount {
        font-weight: 500;
        font-family: Roboto-Bold, sans-serif;
      }

      & .separator {
        height: 50%;
        border-right: dotted 1px #8d98a1;
      }

      & .info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 980px) {
        flex-direction: row;
      }
    }
    &__equal {
      display: none;
      @media screen and (max-width: 980px) {
        display: inline-block;
        color: white;
        font-family: 'Roboto-Medium';
        font-size: 34px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding-right: 35px;
      }
      @media screen and (max-width: 512px) {
        display: none;
      }
    }
    &__images {
      height: 28px;
      width: 28px;
      @media screen and (max-width: 980px) {
        margin: 0 0.6rem;
      }
    }
    &__money {
      height: 28px;
      width: 100%;
      margin: 0 auto;
      margin-top: 14px;
      font-family: 'Roboto-Black';
      font-size: 32px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: center;
      color: #cbee6b;
      @media screen and (max-width: 980px) {
        width: auto;
        margin: 0 0;
        margin-top: 0;
        line-height: 30px;
        margin-left: 0;
      }
      @media screen and (max-width: 780px) {
        margin: 0;
      }
      &__min {
        font-size: 16px !important;
      }
      &__type {
        font-family: 'Roboto-Regular';
      }
    }
  }
}
</style>
